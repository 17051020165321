/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';
import "../../bdthemes/dashboard/AccessControl/resources/assets/public/css/styles.css"
import { createApp} from 'vue';
import { createPinia } from 'pinia';
import { mixin } from '@accessControlSrc/mixins/mixin' // require mixin file
import router from './router';

import { VueReCaptcha } from "vue-recaptcha-v3";


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import BdThemesApp from '@accessControlSrc/components/layout/App';
import { vCan, vCannot, vAuthCan, vAuthCannot } from '@accessControlSrc/directive/directives';

const pinia = createPinia();
const app = createApp(BdThemesApp);
app.directive('can', vCan)
app.directive('cannot', vCannot)
app.directive('auth-can', vAuthCan)
app.directive('auth-cannot', vAuthCannot)
app.use(VueReCaptcha, { siteKey: bdThemesPublicConfig?.recaptcha?.key })

app.use(pinia)
    .use(router)
    .use(mixin);
app.mount('#app');



