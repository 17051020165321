<template>
    <div :class="{'flex h-screen overflow-y-hidden bg-white':!!author?.id}">
        <router-view name="SidebarLeft"></router-view>
        <div class="flex flex-col flex-1 h-full overflow-hidden">
            <router-view name="AuthHeader"></router-view>
            <template v-if="abortCode > 300">
                <ErrorContent></ErrorContent>
            </template>
            <router-view v-else name="Content"></router-view>
            <AppFooter></AppFooter>
        </div>
    </div>
</template>

<script setup>
import {useBdThemesAppStore} from "@accessControlSrc/stores/bdthemesApp"
import AppFooter from "./AppFooter"
import ErrorContent from "./../errors/index"
import {computed} from "vue";

import { onMounted } from 'vue';

onMounted(() => {
    if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark')
    }
});


const appStore = useBdThemesAppStore();
const abortCode = computed(() => appStore.abort_code);
const author = computed(() => appStore.getAuthor);
</script>

