<template>
    <main class="flex-1 max-h-full overflow-hidden overflow-y-scroll bg-gray-100">
            <div class="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
                <FourZeroOne :message="abortMessage" v-if="abortCode == 401"></FourZeroOne>
                <FourZeroThree :message="abortMessage" v-else-if="abortCode == 403"></FourZeroThree>
                <FourZeroFour :message="abortMessage" v-else-if="abortCode == 404"></FourZeroFour>
                <FourNineteen :message="abortMessage" v-else-if="abortCode == 419"></FourNineteen>
                <FiveHundred :message="abortMessage" v-else-if="abortCode == 500"></FiveHundred>
                <Unknown :message="abortMessage" v-else></Unknown>
            </div>
    </main>
</template>


<script setup>
import {useBdThemesAppStore} from "@accessControlSrc/stores/bdthemesApp"
import {computed} from "vue";
import FourZeroOne from "./401";
import FourZeroThree from "./403";
import FourZeroFour from "./404";
import FourNineteen from "./419";
import FiveHundred from "./500";
import Unknown from "./unknown";

const appStore = useBdThemesAppStore();
const abortCode = computed(() => appStore.abort_code);
const abortMessage = computed(() => appStore.abort_message);
</script>
